import * as React from 'react'

export function Zigbee(props) {
  return (
    <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' {...props}>
     <path d="M48.773,31.617C46.776,27.396 46.284,26.105 46.284,26.105L41.925,26.105"
     fill='currentColor'
     fillRule='evenodd'/>
    <path d="M51.197,31.617C53.195,27.396 53.686,26.105 53.686,26.105L58.046,26.105"
    fill='currentColor'
     fillRule='evenodd'/>
    <path d="M43.812,69.316C45.667,70.063 47.78,70.486 50.023,70.486C52.235,70.486 54.322,70.074 56.158,69.346C54.678,72.127 52.464,73.895 49.993,73.895C47.513,73.895 45.293,72.114 43.812,69.316ZM42.583,66.773C42.258,65.583 42.043,64.301 41.959,62.958C44.196,64.255 46.989,65.026 50.016,65.026C53.016,65.026 55.786,64.269 58.011,62.993C57.925,64.337 57.707,65.619 57.379,66.808C55.271,67.877 52.739,68.5 50.016,68.5C47.264,68.5 44.706,67.863 42.583,66.773ZM41.925,61.061C41.96,59.661 42.095,58.655 42.392,57.406C44.543,59.333 55.401,59.31 57.542,57.406C57.837,58.655 58.012,59.687 58.046,61.086C55.671,61.902 52.934,62.368 50.022,62.368C47.08,62.368 44.317,61.893 41.925,61.061ZM49.985,31.617C52.898,31.617 55.292,33.982 55.292,36.895C55.292,37.889 55.017,38.82 54.538,39.614C54.572,39.658 54.606,39.703 54.638,39.749C56.593,42.49 54.93,42.06 60.434,42.727C65.908,43.39 79.355,48.194 81.889,49.51C86.286,51.794 84.845,54.795 83.234,56.04C80.228,58.363 70.884,59.522 68.345,58.614C60.561,55.832 56.298,48.567 55.563,49.345C53.77,51.245 49.985,50.834 49.985,50.834L49.985,31.617ZM49.985,50.834L49.985,31.617C47.072,31.617 44.708,33.982 44.708,36.895C44.708,37.889 44.983,38.82 45.462,39.614C45.428,39.658 45.394,39.703 45.362,39.749C43.407,42.49 45.07,42.06 39.566,42.727C34.092,43.39 20.645,48.194 18.111,49.51C13.714,51.794 15.155,54.795 16.766,56.04C19.772,58.363 29.116,59.522 31.655,58.614C39.439,55.832 43.702,48.567 44.437,49.345C46.23,51.245 49.985,50.834 49.985,50.834ZM42.597,55.951C43.018,54.43 43.621,53.063 44.362,51.919C45.816,52.606 47.8,53.029 49.985,53.029C52.171,53.029 54.154,52.606 55.608,51.919C56.349,53.063 56.952,54.43 57.373,55.951C55.397,56.553 52.813,56.918 49.985,56.918C47.158,56.918 44.573,56.553 42.597,55.951Z"
     fill='currentColor'
     fillRule='evenodd'/>
    </svg>
  )
}
