import * as React from 'react'

export function Bridge(props) {
  return (
    <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' {...props}>
     <path d="M82.5,33.194C82.5,24.532 75.478,17.5 66.828,17.5L33.172,17.5C24.522,17.5 17.5,24.532 17.5,33.194L17.5,66.806C17.5,75.468 24.522,82.5 33.172,82.5L66.828,82.5C75.478,82.5 82.5,75.468 82.5,66.806L82.5,33.194ZM49.723,37.795C56.514,37.795 62.027,43.316 62.027,50.116C62.027,56.916 56.514,62.437 49.723,62.437C42.932,62.437 37.419,56.916 37.419,50.116C37.419,43.316 42.932,37.795 49.723,37.795ZM49.723,41.308C54.578,41.308 58.52,45.255 58.52,50.116C58.52,54.978 54.578,58.925 49.723,58.925C44.868,58.925 40.927,54.978 40.927,50.116C40.927,45.255 44.868,41.308 49.723,41.308ZM35.759,26.455C37.071,26.455 38.136,27.521 38.136,28.834C38.136,30.148 37.071,31.214 35.759,31.214C34.447,31.214 33.382,30.148 33.382,28.834C33.382,27.521 34.447,26.455 35.759,26.455ZM49.816,26.455C51.128,26.455 52.193,27.521 52.193,28.834C52.193,30.148 51.128,31.214 49.816,31.214C48.505,31.214 47.44,30.148 47.44,28.834C47.44,27.521 48.505,26.455 49.816,26.455ZM63.882,26.455C65.193,26.455 66.258,27.521 66.258,28.834C66.258,30.148 65.193,31.214 63.882,31.214C62.57,31.214 61.505,30.148 61.505,28.834C61.505,27.521 62.57,26.455 63.882,26.455Z" 
     fill='currentColor'
     fillRule='evenodd'/>
    
    </svg>
  )
}
