import * as React from 'react'

export function Tradfri(props) {
  return (
    <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' {...props}>
     <path d="M50,17.439C67.937,17.439 82.5,32.029 82.5,50C82.5,67.971 67.937,82.561 50,82.561C32.063,82.561 17.5,67.971 17.5,50C17.5,32.029 32.063,17.439 50,17.439ZM50,20.227C66.4,20.227 79.712,33.569 79.712,50C79.712,66.431 66.4,79.773 50,79.773C33.6,79.773 20.288,66.431 20.288,50C20.288,33.569 33.6,20.227 50,20.227Z" 
     fill='currentColor'
    fillRule='evenodd'/>
    <path d="M50,25.379C63.563,25.379 74.575,36.411 74.575,50C74.575,63.589 63.563,74.621 50,74.621C36.437,74.621 25.425,63.589 25.425,50C25.425,36.411 36.437,25.379 50,25.379ZM50,34.623C58.466,34.623 65.331,41.518 65.331,50C65.331,58.482 58.466,65.377 50,65.377C41.534,65.377 34.669,58.482 34.669,50C34.669,41.518 41.534,34.623 50,34.623Z" 
    fill='currentColor'
    fillRule='evenodd'/>
    </svg>
  )
}
