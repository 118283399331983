import * as React from 'react'

export function Diybridge(props) {
  return (
    <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' {...props}>
     <path d="M82.5,29.491C82.5,27.82 81.143,26.464 79.472,26.464L20.528,26.464C18.857,26.464 17.5,27.82 17.5,29.491L17.5,70.509C17.5,72.18 18.857,73.536 20.528,73.536L79.472,73.536C81.143,73.536 82.5,72.18 82.5,70.509L82.5,29.491ZM21.588,67.818C22.477,67.818 23.199,68.54 23.199,69.429C23.199,70.318 22.477,71.04 21.588,71.04C20.699,71.04 19.977,70.318 19.977,69.429C19.977,68.54 20.699,67.818 21.588,67.818ZM78.433,67.818C79.322,67.818 80.043,68.54 80.043,69.429C80.043,70.318 79.322,71.04 78.433,71.04C77.544,71.04 76.822,70.318 76.822,69.429C76.822,68.54 77.544,67.818 78.433,67.818ZM79.87,44.662L67.321,44.662L67.321,62.714L79.87,62.714L79.87,44.662ZM29.003,55.733L22.646,55.733L22.646,62.714L29.003,62.714L29.003,55.733ZM63.993,45.792C63.993,45.168 63.487,44.662 62.864,44.662L47.558,44.662C46.934,44.662 46.428,45.168 46.428,45.792L46.428,61.225C46.428,61.849 46.934,62.355 47.558,62.355L62.864,62.355C63.487,62.355 63.993,61.849 63.993,61.225L63.993,45.792ZM40.578,32.509L28.134,32.509L28.134,48.385L40.578,48.385L40.578,32.509ZM78.433,28.728C79.322,28.728 80.043,29.45 80.043,30.339C80.043,31.228 79.322,31.95 78.433,31.95C77.544,31.95 76.822,31.228 76.822,30.339C76.822,29.45 77.544,28.728 78.433,28.728ZM21.588,28.728C22.477,28.728 23.199,29.45 23.199,30.339C23.199,31.228 22.477,31.95 21.588,31.95C20.699,31.95 19.977,31.228 19.977,30.339C19.977,29.45 20.699,28.728 21.588,28.728Z" 
     fill='currentColor'
     fillRule='evenodd'/>
    
    </svg>
  )
}
